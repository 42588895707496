import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import Layout from "../components/Layout"

export default function PrivacyPolicy({
  data: {
    strapiPrivacyPolicy: { title, content },
  },
}) {
  return (
    <Layout>
      <Helmet title={title} />
      <section className="section">
        <div className="container">
          <div className="content legal-content">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {content.data.content}
            </ReactMarkdown>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiPrivacyPolicy {
      title
      content {
        data {
          content
        }
      }
    }
  }
`
